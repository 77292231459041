<template>
  <div class="my-upload-container">
    <el-form :model="form" label-width="80px">
      <el-form-item label="文件" prop="file">
        <el-upload
          :file-list="fileList"
          class="upload-demo"
          ref="upload"
          action
          :auto-upload="false"
          drag
          multiple
          accept=".jfif,.pjpeg,.jpeg,.pjp,.jpg,.png,.webp,.gif,.m4v,.mp4,.mov,.webm,.wma,.avi,.mov,.3gp,.mpeg,.mp4,.flv,.swf,.ts"
          :on-change="handleUploadFile"
          :limit="1"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input v-model="form.remark"></el-input>
      </el-form-item>
      <el-form-item>
        <div style="color:red">
          建议填写清晰明确的备注,以便于发帖时素材的检索、区分
        </div>
      </el-form-item>
      <el-form-item>
        <el-button
          style="margin-left: 10px;"
          size="small"
          type="success"
          @click="submitUpload"
          v-loading.fullscreen.lock="uploadLoading"
        >
          上传到服务器
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { Message } from 'element-ui'
import { myUpload } from '@/plugins/upload'

export default {
  data() {
    return {
      form: {
        remark: '',
        file: ''
      },
      fileList: [],
      uploadLoading: false
    }
  },
  methods: {
    async submitUpload() {
      if (this.form.file) {
        // TODO 文件上传回调
        this.uploadLoading = true
        try {
          await myUpload(this.form.file, this.form.remark)
          this.fileList = []
          this.form.file = null
          this.form.remark = ''
          // location.reload()
        } catch (error) {
        } finally {
          this.uploadLoading = false
        }
      } else {
        Message.warning('请先选择文件')
      }
    },
    handleUploadFile(file) {
      // console.log('操作文件上传', file)
      this.form.file = file
    }
  }
}
</script>

<style scoped>
.my-upload-container {
  height: 800px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
